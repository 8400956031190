import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import FrontendProjects from './projects-array';

const FullPageProjectModal = ({ darkMode = false }) => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const modalRef = useRef(null);
  const contentRef = useRef(null);
  const headerRef = useRef(null);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const id = parseInt(projectId);

  // Find the current project based on ID
  const projectData = FrontendProjects.find(p => p.id === id) || FrontendProjects[0];

  // Handle close action - navigate back to previous page
  const handleClose = () => {
    navigate('/'); // Navigate to home page
    
    // Use setTimeout to ensure navigation completes before scrolling
    setTimeout(() => {
      const projectsSection = document.getElementById('projects');
      if (projectsSection) {
        projectsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  // Lock body scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  
  // In the modal component
  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  // Helper function to determine if file is video
  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'webm', 'ogg'];
    const extension = url.split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  useEffect(() => {
    document.addEventListener('keydown', closeModalOnEscape);
    return () => {
      document.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  // Focus trap effect
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, []);

  // Safely get data with fallback
  const skills = projectData?.modalSkills || projectData?.skills || [];
  const visuals = projectData?.visuals || [];
  const features = projectData?.features || [];
  const process = projectData?.process || [];
  const description = projectData?.modalDescription || projectData?.description || 'No description available';
  const title = projectData?.title || 'No title available';

  const handleVideoClick = (videoUrl) => {
    setPlayingVideo(playingVideo === videoUrl ? null : videoUrl);
  };

  const MediaItem = ({ src, index }) => {
    if (isVideo(src)) {
      return (
        <div className="relative w-full">
          {playingVideo === src ? (
            <video
              src={src}
              className="w-full h-auto rounded-lg shadow-lg"
              controls
              autoPlay
              onClick={() => handleVideoClick(src)}
            />
          ) : (
            <div 
              className="relative cursor-pointer"
              onClick={() => handleVideoClick(src)}
            >
              <video
                src={src}
                className="w-full h-auto rounded-lg shadow-lg"
                muted
                playsInline
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-lg">
                <svg 
                  className="w-16 h-16 text-white" 
                  fill="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path d="M8 5v14l11-7z"/>
                </svg>
              </div>
            </div>
          )}
        </div>
      );
    }
    return (
      <img
        src={src}
        alt={`Project visual ${index + 1}`}
        loading="lazy"
        className="w-full h-auto rounded-lg shadow-lg hover:scale-[1.01] transition-transform duration-300"
      />
    );
  };

  return (
    <div
      ref={modalRef}
      className={`fixed inset-0 z-50 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}
      aria-modal="true"
      role="dialog"
      aria-labelledby="project-title"
    >
      {/* Header with back button and project title */}
      <header 
        ref={headerRef}
        className={`fixed top-0 left-0 right-0 z-50 py-4 px-6 md:px-12 ${darkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'} border-b shadow-sm flex items-center justify-between`}
      >
        <div className="flex items-center gap-4">
          <button
            onClick={handleClose}
            className={`p-2 rounded-full ${darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'} transition-colors flex items-center gap-2`}
            aria-label="Return to projects"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
            </svg>
            <span className="hidden sm:inline">Back to projects</span>
          </button>
        </div>
        
        <h1 id="project-title" className="text-xl md:text-2xl font-bold truncate">{title}</h1>
        
        <div className="flex items-center gap-3">
          {projectData.githubUrl && (
            <a 
              href={projectData.githubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center gap-1 px-3 py-1.5 rounded-lg text-sm ${
                darkMode 
                  ? 'bg-gray-800 text-white hover:bg-gray-700' 
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              } transition-colors`}
              aria-label="View code on GitHub"
            >
              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
              </svg>
              <span className="hidden md:inline">Code</span>
            </a>
          )}
          {projectData.liveUrl && (
            <a 
              href={projectData.liveUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center gap-1 px-3 py-1.5 rounded-lg text-sm ${
                darkMode 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              } transition-colors`}
              aria-label="View live demo"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
              </svg>
              <span className="hidden md:inline">Live Demo</span>
            </a>
          )}
        </div>
      </header>

      {/* Rest of the component remains the same */}
      {/* Main content area */}
      <div 
        ref={contentRef}
        className="h-full overflow-y-auto pt-20 pb-12"
        tabIndex={-1}
      >
        {/* The rest of your component... */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Main content layout */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
            {/* Left sidebar */}
            <div className="lg:col-span-4 space-y-10">
              {/* Overview section */}
              <section>
                <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                  Overview
                </h2>
                <div 
                  className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none`}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </section>

              {/* Technologies & Skills */}
              {skills.length > 0 && (
                <section>
                  <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    Technologies & Skills
                  </h2>
                  <div className="flex flex-wrap gap-2 mb-6">
                    {skills.map((skill, index) => (
                      <span
                        key={index}
                        className={`px-3 py-1.5 rounded-full text-sm font-medium ${
                          darkMode ? 'bg-blue-900/30 text-blue-100' : 'bg-blue-100 text-blue-800'
                        }`}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </section>
              )}

              {/* Development Process */}
              {process.length > 0 && (
                <section>
                  <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    Development Process
                  </h2>
                  <ul className={`space-y-3 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {process.map((processStep, index) => (
                      <li key={index} className="flex gap-3">
                        <div className={`mt-1 flex-shrink-0 flex items-center justify-center w-6 h-6 rounded-full text-xs font-medium ${
                          darkMode ? 'bg-blue-900/30 text-blue-100' : 'bg-blue-100 text-blue-800'
                        }`}>
                          {index + 1}
                        </div>
                        <div>{processStep}</div>
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {/* UX/UI Details */}
              {projectData.ux && projectData.ux.length > 0 && (
                <section>
                  <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    UX/UI
                  </h2>
                  <ul className={`space-y-3 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {projectData.ux.map((item, index) => (
                      <li key={index} className="flex gap-3">
                        <div className={`flex-shrink-0 mt-1`}>
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                          </svg>
                        </div>
                        <div>{item}</div>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
            </div>

            {/* Right content area */}
            <div className="lg:col-span-8 space-y-12">
              {/* Project Visuals */}
              {visuals.length > 0 && (
                <section>
                  <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    Project Showcase
                  </h2>
                  <div className="space-y-8">
                    {visuals.map((visual, index) => (
                      <div key={index} className="w-full overflow-hidden rounded-xl shadow-lg">
                        <MediaItem src={visual} index={index} />
                      </div>
                    ))}
                  </div>
                </section>
              )}

              {/* Key Features */}
              {features.length > 0 && (
                <section>
                  <h2 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    Key Features
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {features.map((feature, index) => (
                      <div 
                        key={index} 
                        className={`p-6 rounded-lg ${
                          darkMode ? 'bg-gray-800/50' : 'bg-gray-50'
                        }`}
                      >
                        <div className="flex items-start gap-3">
                          <div className={`flex-shrink-0 p-2 rounded-lg ${
                            darkMode ? 'bg-blue-900/30 text-blue-300' : 'bg-blue-100 text-blue-600'
                          }`}>
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                          </div>
                          <div className={darkMode ? 'text-gray-300' : 'text-gray-700'}>
                            {feature}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageProjectModal;