const projectsData= [
{
    id: 3,
    slug: 'Extranet Relayout',
    title: 'Extranet Relayout',
    client: 'Vila Velha Insurance Broker',
    year: '2025',
    role: 'Lead UX/UI Designer',
    heroImage: '/images/vl/new-design-thumb.png',
    skills: ["UX Research", "UI Design", "Prototyping", "Design Systems", "Wireframing", "User Research", "Persona Creation", "Problem Solving"],
    overview: `To enhance usability and efficiency, I redesigned the extranet system with a modern, scalable, and user-centric experience.
     The redesign focused on optimizing performance, streamlining navigation, and improving accessibility across devices.
     <br />

    I led the prototyping of five key pages, designing intuitive desktop and mobile experiences—totaling 10 pages. This initiative laid the foundation 
    for a more seamless user journey, reducing friction and enhancing overall engagement.
    <br /><br /> <a href='https://gentle-imagine-000316.framer.app/' target='_blank' class='text-blue-600 hover:underline'>See the live Prototype</a>`, 
    challenge: "The existing platform had an outdated interface with poor information architecture, leading to high bounce rates and low conversion.",
    process: [
      {
        title: 'User Research',
        description: 'Conducted interviews with 10 users across different demographics to understand their pain points with the existing platform.',
      },
      {
        title: 'Information Architecture',
        description: 'Restructured the content hierarchy to improve navigation and discoverability of key features.',
      },
      {
        title: 'Visual Design System',
        description: 'Created a comprehensive design system with modular components for consistent user experience.',
      },
      {
        title: 'Prototyping & Testing',
        description: 'Developed interactive prototypes and conducted usability testing with key stakeholders.',
      }
    ],
    outcome: [
      "• Modular and Responsive Layout: Improved usability across devices by XX%, ensuring seamless adaptation to various screen sizes.",
      "• Simplified Navigation: Applied progressive disclosure techniques, reducing task completion time by XX% and enhancing decision-making efficiency.",
      "• Customizable Dashboard: Increased user engagement by XX% by allowing users to personalize their interface based on their needs.",
      "• Modern Aesthetic: Delivered a minimalist yet functional design, improving overall user satisfaction by XX% while maintaining high performance.",
      "• Commercial Optimization: Integrated strategically placed CTAs, leading to a XX% boost in conversions."
    ],
    // Consolidated all project images into one gallery array
    gallery:  ["/images/vl/vl1.png", "/images/vl/vl2.png", "/images/vl/vl3.png", 
      "/images/vl/4.png", "/images/vl/5.png", "/images/vl/6.gif", "/images/vl/15.gif",
      "/images/vl/8.png","/images/vl/9.png", "/images/vl/10.png", "/images/vl/11.png",
      "/images/vl/12.png", "/images/vl/13.png", "/images/vl/14.png", "/images/vl/18.gif",
      "/images/vl/16.png", "/images/vl/17.png", "/images/vl/10.gif","/images/vl/19.png",
      "/images/vl/20.png"]
    
  },
  {
    id: 1,
    slug: 'Kinkoa Web App',
    title: 'Kinkoa Web App',
    client: 'Kinkoa',
    year: '2024',
    role: 'UX/UI Designer',
    heroImage: '/images/kinkoa/k-thumb.jpg',
    skills: ["UX/UI Design", "Wireframing", "Prototyping", "User-centric Thinking", "Problem Solving"],
    overview: `To enhance the customer experience for Kinkoa, a fruit milk-based juice brand operating a juice truck, 
    I designed an intuitive tablet interface that streamlined product discovery and ordering. The UX/UI prioritized quick browsing, 
    enabling seamless navigation through the juice selection while reinforcing the brand’s eco-conscious mission through strategic messaging on recycled glass bottles. 
    <br />
    This redesign led to a measurable improvement in engagement: order completion time decreased by XX%, user interactions with the sustainability messaging increased by XX%, 
    and customer satisfaction scores saw a notable uplift.
    <br /> 

     Importantly, the new interface not only enhanced operational efficiency but also strengthened Kinkoa’s brand identity, fostering deeper customer connection and loyalty.
    `,
    challenge: 'The challenge was to create a user-centric tablet interface that showcased Kinkoa\'s limited juice flavors while reflecting their eco-conscious brand values in a fast-paced, in-store environment.',
    process: [
      {
        title: 'User Research',
        description: 'Conducted research on the target demographic to identify user needs, preferences, and pain points for in-store tablet interaction.',
      },
      {
        title: 'Information Architecture',
        description: 'Restructured the app’s content flow, ensuring quick access to juice flavors and making the interface intuitive for fast browsing.',
      },
      {
        title: 'Visual Design System',
        description: 'Created a clean, modern design with eco-conscious branding elements that communicated sustainability through messaging about recycled glass bottles.',
      },
      {
        title: 'Prototyping & Testing',
        description: 'Built interactive prototypes using Figma, testing the user interface with stakeholders to ensure a smooth and engaging tablet interaction.',
      }
    ],
    outcome: [
      "• Frontend User Flow Optimization: Created a tablet-based interface that promotes quick and engaging browsing, prioritizing simplicity and ease of use.",
      "• Responsive UX Design: Optimized the design for in-store tablet use, considering real-time interaction and device layout constraints.",
      "• Brand Integration: Focused on aligning the design with Kinkoa’s sustainability values, emphasizing eco-friendly messages and creating a cohesive, branded experience.",
      "• Wireframing and Prototyping: Developed interactive prototypes in Figma, ensuring a smooth transition from design to development.",
      "• User-Centric Design: Prioritized user needs with easy access to juice flavors and intuitive navigation.",
      "• Eco-Conscious Messaging: Highlighted Kinkoa's sustainability efforts, integrating messaging about recycled glass bottles into the design.",
      "• Interactive Prototyping: Used Figma to create a working prototype, demonstrating how the final product would function on the in-store tablet."
    ],
    gallery: [
     
      '/images/kinkoa/k-thumb.jpg', 
      "/images/kinkoa/k1.png", "/images/kinkoa/k2.gif", "/images/kinkoa/k3.png", "/images/kinkoa/k4.png",
        "/images/kinkoa/k5.gif", "/images/kinkoa/k6.png", "/images/kinkoa/k7.png",  "/images/kinkoa/k8.gif", "/images/kinkoa/k9.png",
        "/images/kinkoa/k10.mp4",  "/images/kinkoa/11.mp4", "/images/kinkoa/k12.gif", '/images/kinkoa/new-thumb-k.png' ]

    
  },
  {
    id: 5,
    slug: 'Motion UX',
    title: 'Motion UX',
    client: 'Reckitt Brazil',
    year: '2022',
    role: 'UX/UI Motion Designer & Art Director',
    heroImage: '/images/reckitt/reckitt-mockup.png',
    skills:["Motion Design", "UX/UI Design", "Animation", "After Effects", "CreativeX", "Collaboration", "Digital Marketing", "Team Coordination", "Video Editing"],
    overview: `For Reckitt Brazil’s Black Friday campaign, I led the UX/UI motion design, crafting high-impact animated content for YouTube, Amazon Ads, and Instagram. 
    Collaborating closely with sales,
     commercial, and creative teams, I ensured seamless alignment between visuals and strategic messaging to maximize engagement.
     <br />
     This initiative drove measurable success: ad interaction rates increased by XX%, campaign visibility improved across key platforms, 
     and turnaround times for content delivery were optimized to meet tight deadlines.
    `,
    challenge: "The biggest challenge was animating several video pieces under tight time constraints, which required efficient collaboration and quick decision-making across different teams.",

    process: [
      {
        title: 'Cross-Functional Collaboration',
        description: 'Collaborated closely with different teams such as sales, commercial, copywriting, and other creative professionals to deliver high-quality visual content under tight deadlines.',
      },
      {
        title: 'Motion Design & Animation',
        description: 'Used After Effects and CreativeX to animate video content for various platforms, focusing on smooth motion design and seamless user experience within the digital marketing context.',
      },
      {
        title: 'Digital Marketing Strategy',
        description: 'Ensured the animated content adhered to digital marketing principles, optimizing for user engagement and conversions across YouTube, Amazon Ads, and Instagram.',
      },
      {
        title: 'Content Delivery Under Pressure',
        description: 'Managed time effectively to deliver animated pieces quickly without compromising on quality, handling the pressure of a fast-paced marketing campaign.'
      }
    ],
    outcome: [
      "• High-Quality Animated Content: Produced engaging and visually appealing videos using After Effects and CreativeX for platforms like YouTube, Amazon Ads, and Instagram.",
      "• Cross-Platform Optimization: Ensured all video content was optimized for different digital marketing platforms, with a focus on user engagement and maximizing conversions.",
      "• Efficient Collaboration: Worked closely with multiple teams to streamline content production and meet tight deadlines, delivering high-quality results under pressure.",
      "• Digital Marketing Success: Contributed to a successful Black Friday campaign that resonated with audiences across digital channels, boosting brand visibility and sales.",
      "• Motion Design Excellence: Emphasized smooth animation techniques, ensuring seamless visual storytelling in all marketing videos."
    ],
    gallery: [
      '/images/reckitt/rt1.gif', 
      "/images/reckitt/rt2.gif", "/images/reckitt/rt3.png", "/images/reckitt/rt4.gif", "/images/reckitt/rt5.gif",
        "/images/reckitt/rt6.mp4", "/images/reckitt/rt7.mp4", "/images/reckitt/rt8.png",  "/images/reckitt/rt9.gif", "/images/reckitt/rt10.gif",
        "/images/reckitt/rt11.mp4",  "/images/reckitt/rt12.mp4", "/images/reckitt/rt13.png",  "/images/reckitt/rt14.gif", "/images/reckitt/rt15.mp4",
        "/images/reckitt/rt16.gif",  "/images/reckitt/rt17.gif" ,  "/images/reckitt/rt18.gif",  "/images/reckitt/rt19.gif" ]

    
  },
  {
    id: 7,
    slug: 'Colab Start - Google for Education',
    title: 'Colab Start - Google for Education',
    client: 'Google for Education',
    year: '2023',
    role: 'UX/UI Analyst & Designer',
    heroImage: '/images/goog/gf-mockup.png',
    skills:["UX Research", "UI Design", "Prototyping", "Interactive Design", "Persona Creation", "User Journey Mapping", "Design Systems"],
    overview: `For Google for Education’s Colab Start program, I designed an interactive prototype to enhance the onboarding experience, streamline usability,
     and boost engagement for educators and students. By refining the user journey, I optimized accessibility and reduced friction in key interactions.`,
    challenge: "Educators and students faced difficulties navigating the platform due to an overwhelming onboarding process and unclear feature presentation.",

    process: [
      {
        title: 'User Research',
        description: 'Conducted surveys and interviews with educators to understand their pain points and expectations, informing persona creation and UX strategy.',
      },
      {
        title: 'Information Architecture',
        description: 'Streamlined the onboarding flow by restructuring content and optimizing the user journey to minimize cognitive load.',
      },
      {
        title: 'Visual Design & Prototyping',
        description: 'Created an interactive prototype in Sketch, integrating transitions and interactive elements to enhance engagement and usability.',
      },
      {
        title: 'Design System Implementation',
        description: 'Applied a consistent visual language and modular components to maintain scalability and brand coherence.'
      }
    ],
    outcome: [
      "• Streamlined onboarding experience: Users can quickly navigate and understand platform features.",
      "• Interactive design approach: Engaging transitions and dynamic elements improved user engagement.",
      "• Research-backed user journey: Ensured that the design met the needs of educators and students.",
      "• Scalable and cohesive design system: Enabled easy adaptation and future iterations.",
      
    ],
    gallery: [
      "/images/goog/gfe1.png",
      '/images/goog/gf2.png', 
      "/images/goog/gf3.png", "/images/goog/gf4.png", "/images/goog/gf5.png", "/images/goog/gf5.png",
      '/images/goog/gf-mockup.png'
         ]
        },
         {
          id: 2,
          slug: 'Touristic Landing Page',
          title: 'Touristic Landing Page',
          client: 'Bahia Van Tour',
          year: '2024',
          role: 'UX/UI & Web Designer',
          heroImage: '/images/vantour/v-thumb.jpg',
          skills:["UI Design", "Figma", "Prototyping", "Frontend Development", "Image Curation", "Stock & AI Image Editing"],
          overview: `Faced with a lack of online presence and credibility, the Salvador-based touristic van service needed a digital solution to attract and convert potential customers. 
          I designed a landing page in Figma that seamlessly integrated their existing brand identity from social media, ensuring visual consistency and reinforcing trust.
          <br />
          This transformation led to measurable improvements: website inquiries increased by XX%, customer trust metrics improved by XX%, and digital engagement saw a significant boost.
           <br /><br /> <a href='https://www.figma.com/proto/uLWAsONKTcmhrZC0z0zvdS/Ariane-Souza-Projetos?node-id=929-24&t=ow9rhSQmMhTRQLFp-1' target='_blank' class='text-blue-600 hover:underline'>See the live Prototype</a>`,
          challenge: "The business had no prior website and lacked high-quality images to showcase their services, vehicles, and customers, making it difficult to build a visually appealing and trustworthy site.",
      
          process: [
            {
              title: 'Brand Integration',
              description: 'Utilized existing colors and typography from their Instagram account to maintain brand consistency.',
            },
            {
              title: 'Image Curation & Editing',
              description: 'Streamlined the onboarding flow by restructuring content and optimizing the user journey to minimize cognitive load.',
            },
            {
              title: 'Content Structuring',
              description: 'Designed an intuitive layout to highlight tour services, van details, and business credibility.',
            },
            {
              title: 'High-Fidelity Prototyping',
              description: 'Developed an interactive prototype in Figma with real interactions, making it functionally similar to a live website.'
            }
          ],
          outcome: [
            "• Professional Online Presence: Provided a website that conveys trust and credibility to potential clients.",
  "• Cohesive Visual Identity: Ensured consistency with the brand’s existing social media presence.",
  "• Engaging Imagery: Curated and edited visuals to create an appealing and immersive experience.",
  "• Improved Accessibility: Organized information to make services easily understandable for tourists.",
  "• Realistic Prototype: Created a Figma prototype with real interactions, closely mimicking the final user experience.",
            
          ],
          gallery: [
            "/images/vantour/v1.png",
            '/images/vantour/v2.png', 
            "/images/vantour/v3.png", "/images/vantour/v4.gif", "/images/vantour/v5.gif", "/images/vantour/v6.png",
            '/images/vantour/v7.png','/images/vantour/v8.png','/images/vantour/v9.png', '/images/vantour/v10.png',
            '/images/vantour/v11.gif','/images/vantour/v12.png', '/images/vantour/v13.mp4', '/images/vantour/v14.png',
            '/images/vantour/v15.png', '/images/vantour/v16.png', '/images/vantour/v17.png'
               ]

    
  },
  {
    id: 4,
    slug: 'Grid UI Portfolio',
    title: 'Grid UI Portfolio',
    client: 'Visual Artist',
    year: '2023',
    role: 'Visual & UI Web Designer',
    heroImage: '/images/gridwebsite/gridfolio-thumb.png',
    skills:["Visual Identity", "Icon Design", "UX/UI Design", "HTML5", , "Design System", "CSS", "Boostrap" ],
    overview: `To enhance the artist’s digital presence, I designed an engaging online experience that seamlessly showcases their portfolio, drives e-commerce sales,
     and streamlines partnership inquiries. The platform balances aesthetic appeal with intuitive navigation, ensuring an immersive journey for visitors while supporting the artist’s commercial and collaborative goals.`,
    challenge: [
      " 1 - High-Quality Image Optimization: The website featured a large number of high-quality images, which needed to be optimized to maintain visual integrity while ensuring fast loading times.",
      <br />,
      " 2 - Balancing Visuals and Text: Striking the right balance between imagery and textual content was crucial to effectively present the artist’s work while keeping the website engaging and informative.",
      <br />,
      " 3 - Commercial Appeal: Each project needed to be presented not just as an artwork showcase but with a strong commercial appeal to attract partnerships and e-commerce sales."
    ],
    
    process: [
      {
        title: "Image Optimization & Performance",
        description: "Compressed and adapted high-resolution images using modern formats like WebP while implementing lazy loading techniques to ensure fast page speed without sacrificing quality."
      },
      {
        title: "Content Layout & UX Strategy",
        description: "Developed a structured layout that harmonized visuals with concise, engaging descriptions, enhancing readability and overall presentation."
      },
      {
        title: "E-commerce & Business Positioning",
        description: "Designed sections that emphasized the commercial aspect of the artist’s work, integrating clear CTAs and product highlights to drive conversions."
      }
    ],

    outcome: [
      "• Improved Website Performance: Optimized images and lazy loading reduced load times, enhancing user experience.",
      "• Balanced Content Presentation: Achieved an effective mix of visuals and text, making the site both engaging and informative.",
      "• Strengthened Commercial Appeal: Strategically positioned projects to highlight marketability, increasing partnership inquiries and sales.",
      "• Enhanced Brand Perception: Delivered a professional and visually compelling online presence, reinforcing the artist’s identity."
    ],
    gallery: [
      "/images/gridwebsite/gw1.png",
      "/images/gridwebsite/gw2.png",
      "/images/gridwebsite/gw3.gif",
      "/images/gridwebsite/gw4.png",
      "/images/gridwebsite/gw5.png",
      "/images/gridwebsite/gw6.gif",
      "/images/gridwebsite/gw7.png",
      "/images/gridwebsite/gw8.png",
      "/images/gridwebsite/gw9.gif",
      "/images/gridwebsite/gw10.gif",
      "/images/gridwebsite/gw11.gif",
     "/images/gridwebsite/gw12.png",
     "/images/gridwebsite/gw13.png",
         ]

},
  {
    id: 6,
    slug: 'T10K Mentorship',
    title: 'T10K Mentorship',
    client: 'Mentoring Study Program',
    year: '2023',
    role: 'Visual & UI Designer',
    heroImage: '/images/mentorship/m1.png',
    skills:["Visual Identity", "Icon Design", "Gamification", "UI Design", "Design System"],
    overview: `To address the challenge of maintaining student motivation, I developed the visual identity 
    for a gamified mentoring platform aimed at digital professionals. By incorporating an archery-themed progression system,
     students were encouraged to advance through levels, providing a dynamic and engaging learning experience.
     <br />
     This initiative led to increased user retention, with a noticeable boost in course completion rates and enhanced student 
     satisfaction as they progressed through the interactive learning journey.`,
     
    challenge: "The platform needed a visually engaging system to track student progress, incorporating a medieval and archery theme while ensuring accessibility for a broad age range.",

    process: [
      {
        title: 'Level-Based Visual Identity',
        description: 'Designed target icons for each level, with distinct colors (grey, black, blue, red, and yellow) to signify progression.',
      },
      {
        title: 'Gamification Elements',
        description: 'Created UI elements such as arrows and score-based placements to match traditional archery target scoring.',
      },
      {
        title: 'Archer Silhouette System',
        description: 'Designed archer icons for students who reached level 13, using silhouettes to ensure age-inclusive representation.',
      },
      {
        title: 'User Motivation & Engagement',
        description: 'Crafted an intuitive and visually rewarding experience to keep students engaged and striving for higher levels.'
      }
    ],
    outcome: [
      "• Enhanced Student Motivation: Encouraged learning through a visually immersive level system.",
  "• Cohesive Gamification: Created a structured reward system aligned with archery themes.",
  "• Broad Audience Accessibility: Used silhouettes to represent archers, making the system adaptable for all ages.",
  "• Thematic Consistency: Integrated medieval and archery aesthetics as per client request."      
    ],
    gallery: [
      "/images/mentorship/m1.png",
      '/images/mentorship/mk2.png', 
      '/images/mentorship/mk3.png', "/images/mentorship/mk4.png", "/images/mentorship/mk5.png", "/images/mentorship/mk6.png",
      '/images/mentorship/mk7.png'
         ]


},
];
export default projectsData;