import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from "./components/version3";
import About from "./components/about-v3";
import Services from "./components/what-I-do";
import Projetest from "./components/projects-sectionv4";
import Contact from "./components/Contact";
import ReactCategory from "./ReactCategory"; 
import FSCategory from "./FSCategory";
import "./App.css";
import UXCategory  from "./ux"; 
import ProjectPage from "./components/project-overlay";
import FullPageProjectModal from "./components/ProjectModal";  


const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setDarkMode(true);
    }
  }, []);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <Router>
      <AppContent darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </Router>
  );
};

const AppContent = ({ darkMode, toggleDarkMode }) => {
  const location = useLocation();
  return (
    <div
      className={`min-h-screen transition-colors duration-300 ${
        darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50 text-gray-800"
      }`}
    >
      {/* Only show Header if NOT on /react or /ux page */}
      {(location.pathname !== "/react" && location.pathname !== "/ux") && (
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      )}
      
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero darkMode={darkMode} />
              <Projetest darkMode={darkMode} />
              <About darkMode={darkMode} />
              <Services darkMode={darkMode} />
              <Contact darkMode={darkMode} />
            </>
          }
        />
        <Route path="/react" element={<ReactCategory darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/fullstack" element={<FSCategory darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/ux" element={<UXCategory darkMode={false} toggleDarkMode={toggleDarkMode} forceLight={true} />} />
        <Route path="/project/:projectId" element={<ProjectPage />} />
        <Route path="/FrontendProjects/:projectId" element={<FullPageProjectModal darkMode={darkMode} />} />
      </Routes>
      
      {/* Show Footer on all pages EXCEPT /react */}
      {location.pathname !== "/react" && <Footer darkMode={darkMode} />}
    </div>
  );
};

export default App;
